@import 'styles/core/typography';
@import 'styles/layout';

.container {
  background: var(--joompro-core-color-white);
  padding-bottom: 100px;

  @include media-max-target('tablet-medium') {
    padding-bottom: 60px;
  }
}

.grid {
  align-items: center;
  background: var(--joompro-core-color-black-1000);
  border-radius: 68px;
  display: grid;
  grid-gap: 40px;
  grid-template-areas: 'blockA blockB';
  grid-template-columns: 400px auto;
  padding: 32px 40px;

  @include media-max-target('tablet-medium') {
    border-radius: 32px;
    grid-gap: 20px;
    grid-template-areas: 'blockA' 'blockB';
    grid-template-columns: auto;
    padding: 24px;
    text-align: center;
  }
}

.text {
  @include promoTitleH3;

  color: var(--joompro-core-color-text-main-invert);
  margin-bottom: 16px;

  @include media-max-target('tablet-medium') {
    @include promoSubTitleM;
  }
}

.description {
  @include promoTextL;

  color: var(--joompro-core-color-text-main-invert);
  margin-bottom: 32px;

  @include media-max-target('tablet-medium') {
    margin-bottom: 24px;
  }
}

.content {
  flex-grow: 1;
}

.imageContainer {
  border-radius: 48px;
  height: 320px;
  overflow: hidden;

  @include media-max-target('tablet-medium') {
    border-radius: 28px;
    height: 240px;
    margin: 0 auto;
    max-width: 400px;
  }

  @include media-max-target('tablet-small') {
    height: 200px;
    width: 100%;
  }
}

.image {
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}

.button {
  @include media-max-target('tablet-medium') {
    display: grid;
  }
}
