@import 'styles/layout';

.root {
  background: var(--joompro-core-color-white);
  box-sizing: border-box;
  padding-top: 180px;

  @include media-max-target('tablet-medium') {
    padding-top: 140px;
  }
}
