@import 'styles/core/typography';
@import 'styles/layout';

.root {
  display: grid;
  gap: 60px;

  @include media-max-target('tablet-medium') {
    gap: 32px;
    grid-template-columns: 1fr 1fr;
  }

  @include media-max-target('tablet-small') {
    grid-template-columns: 1fr;
  }
}

.container {
  background: var(--joompro-core-color-white);
  padding-bottom: 100px;
  padding-top: 100px;

  @include media-max-target('tablet-medium') {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.grid {
  align-items: center;
  display: flex;
  gap: 80px;

  @include media-max-target('tablet-medium') {
    flex-direction: column;
    gap: 16px;
  }
}

.text {
  @include promoTitleH3;

  margin-bottom: 20px;

  @include media-max-target('tablet-medium') {
    @include promoTitleH5;

    margin-bottom: 16px;
  }
}

.description {
  @include promoTextL;

  margin-bottom: 32px;

  @include media-max-target('tablet-medium') {
    @include promoTextM;

    margin-bottom: 16px;
  }
}

.content {
  flex-grow: 1;
}

.imageContainer {
  border-radius: 68px;
  min-width: 500px;
  overflow: hidden;

  @include media-max-target('tablet-medium') {
    border-radius: 32px;
    min-width: 352px;
    order: -1;
  }

  @include media-max-target('tablet-small') {
    min-width: 262px;
  }
}

.image {
  height: 100%;
  object-fit: cover;
  object-position: top;
  width: 100%;
}
